export default {
  init() {
    var words = $('h1.hero-text').data('words');
    var direction = 0; // 0 equals up, 1 equals down;


    // $("#text-secondary").text(words[0]);
    var wordcount = words.length - 1;
    var word = 0;
    // var letter = 1;
    var count = 1;
    var speed = 100;

    setInterval(
      function () {

        // $("#text-secondary").text(words[word].slice(0, letter)+word);
        if (count == words[word].length) {
          // if counter is the same as the number of letters in the word we have all the letters
          // alert('max');

          //alert('count == length count:'+count+' length:'+words[word].length);
          if (direction == 0) {
            direction = 1;
            count--;
          } else {
            direction = 0;
            count++;
          }


        } else if (count == 0) {
          // if counter has come back to zero
          //reset counter to 1
          count = 1;
          //change direction back to up
          direction = 0;

          if (word == wordcount) {
            //Reset word to first one

            word = 0;

          } else {
            word++;
          }
          //continue;
        } else {
          // alert('i dono');
          if (direction == 0) {
            count++;
            console.log('up ' + count);
          } else {
            count--;
            console.log('down ' + count);
          }

        }

        // alert('cat');
        console.log('count= ' + count);
        $('#text-secondary').text(words[word].slice(0, count));

      }, speed);

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
