export default {
  init() {
    $('#toggle').click(function () {
      $(this).toggleClass('active');
      $('#overlay').toggleClass('open');
      $('body').toggleClass('menu-open');
    });
    $(window).scroll(function () {
      if ($(this).scrollTop() > 20) {
        $('header').addClass('sticky');
      }
      else {
        $('header').removeClass('sticky');
      }
    });

    if ($(window).width() > 768) {
      $('#menu-primary-navigation li').on('mouseenter', function(){
        var myHover = $(this).find('.link-hover-text').attr('data-hover');
        $('.hover-container').text(myHover);
      });

      $('#menu-primary-navigation li').on('mouseleave', function () {
        $('.hover-container').text('');
      });
    }
    $('a[href*="#content-start"]').on('click', function (e) {
      e.preventDefault()

      $('html, body').animate(
        {
          scrollTop: $($(this).attr('href')).offset().top - 100,
        },
        500,
        'linear'
      )
    });

    $('a[href*="#top"]').on('click', function (e) {
      e.preventDefault()

      $('html, body').animate(
        {
          scrollTop: $($(this).attr('href')).offset().top - 100,
        },
        700,
        'linear'
      )
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
